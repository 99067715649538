import {
    Button,
    INodeProps,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from '@msdyn365-commerce-modules/utilities';
import React from 'react';

export interface ILoyaltyTermsModalProps {
    resources: ILoyaltyTermsModalResources;
    returnRef: React.RefObject<HTMLButtonElement> | undefined;
    checked: boolean;
    isOpen: boolean;
    terms: React.ReactNode;
    onToggle(): void;
    onSubmit(): void;
    onCheck(): void;
}

export interface ILoyaltyTermsModalResources {
    loyaltyTermsHeading: string;
    loyaltyAgreeToTerms: string;
    joinLoyaltyTermsText: string;
    cancelLoyaltyTermsText: string;
}

export interface ILoyaltyTermsModalViewProps {
    modal: INodeProps;
    modalHeader: INodeProps;
    modalFooter: INodeProps;
    modalBody: INodeProps;
    heading: React.ReactElement;
    cancelButton: React.ReactElement;
    submitButton: React.ReactElement;
    terms: React.ReactNode;
    checkbox: React.ReactElement;
    agreeText: React.ReactElement;
    agreeSection: INodeProps;
}

export const LoyaltyTermsModal = (props: ILoyaltyTermsModalProps):ILoyaltyTermsModalViewProps  => {
    return {
        modal: {tag: Modal, className: 'ms-loyalty-terms__modal', toggle: props.onToggle, applicationNode: 'renderPage', isOpen: props.isOpen, returnFocusRef: props.returnRef},
        modalHeader: {tag: ModalHeader, className: 'ms-loyalty-terms__modal-header', toggle: props.onToggle},
        modalFooter: {tag: ModalFooter, className: 'ms-loyalty-terms__modal-footer'},
        modalBody: {tag: ModalBody, className: 'ms-loyalty-terms__modal-body'},
        cancelButton: <Button className='ms-loyalty-terms__cancel' onClick={props.onToggle}>{props.resources.cancelLoyaltyTermsText}</Button>,
        submitButton: <Button className='ms-loyalty-terms__submit' onClick={props.onSubmit} disabled={!props.checked}>{props.resources.joinLoyaltyTermsText}</Button>,
        heading: <p className='ms-loyalty-terms__heading'>{props.resources.loyaltyTermsHeading}</p>,
        terms: props.terms,
        agreeSection: {className: 'ms-loyalty-terms__agree-section'},
        checkbox: <input className='ms-loyalty-terms__checkbox' type='checkbox' checked={props.checked} aria-checked={props.checked} onChange={props.onCheck}/>,
        agreeText: <p className='ms-loyalty-terms__agree'>{props.resources.loyaltyAgreeToTerms}</p>
    };
};