import * as React from 'react';

import {  Module, Node } from '@msdyn365-commerce-modules/utilities';

import { ILoyaltyTermsViewProps } from './loyalty-terms';

const LoyaltyTermsView: React.FC<ILoyaltyTermsViewProps> = props => {
    const {LoyaltyTerms, modalToggle, modal, loading } = props;

    return !loading && modal ? (
            <Module {...LoyaltyTerms}>
                {modalToggle}
                <Node {...modal.modal}>
                    <Node {...modal.modalHeader} >
                        {modal.heading}
                    </Node>
                    <Node {...modal.modalBody} >
                        {modal.terms}
                        <Node {...modal.agreeSection} >
                            {modal.checkbox}
                            {modal.agreeText}
                        </Node>
                    </Node>
                    <Node {...modal.modalFooter} >
                        {modal.submitButton}
                        {modal.cancelButton}
                    </Node>
                </Node>
            </Module>
    )  : null;
};

export default LoyaltyTermsView;
